<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>{{ $t("detail") }}</b>
        <button
          type="button"
          @click="print()"
          class="btn btn-info pull-right"
          title="Print"
        >
          <i class="fa fa-print"></i> {{ $t("print") }}
        </button>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Detail Surat">
              <div class="col-md-12"></div>
              <div class="col-md-6 pull-left">
                <small class="text-muted">{{ $t("document_date") }}</small>
                <h6>
                  {{
                    state.detail.document_date === "" ||
                    state.detail.document_date === undefined
                      ? "-"
                      : formatDate(state.detail.document_date)
                  }}
                </h6>
                <small class="text-muted">{{ $t("from") }}</small>
                <h6>
                  {{
                    state.detail.senderUnit === "" ||
                    state.detail.senderUnit === undefined
                      ? "-"
                      : state.detail.senderUnit
                  }}
                </h6>
                <small class="text-muted">{{ $t("subject") }}</small>
                <h6>
                  {{
                    state.detail.subject === "" ||
                    state.detail.subject === undefined
                      ? "-"
                      : state.detail.subject
                  }}
                </h6>
                <!-- <small class="text-muted">{{ $t("agenda_number") }}</small>
                <h6>
                  {{
                    state.detail.agenda_number === "" ||
                    state.detail.agenda_number === undefined
                      ? "-"
                      : state.detail.agenda_number
                  }}
                </h6> -->
                <small
                  class="text-muted"
                  v-if="state.detail.minister_approval"
                  >{{ $t("minister_approval") }}</small
                >
                <h6 v-if="state.detail.minister_approval">
                  {{ state.detail.status }}
                </h6>
                <!-- <small class="text-muted"
                  >{{ $t("document_type") }} / {{ $t("document_name") }}</small
                > -->
                <small class="text-muted">Jenis Surat</small>
                <h6>
                  {{
                    state.detail.document_category === "" ||
                    state.detail.document_category === undefined
                      ? "-"
                      : state.detail.document_category.text
                  }}
                  <!-- /
                  {{
                    state.detail.document_name === "" ||
                    state.detail.document_name === undefined
                      ? "-"
                      : state.detail.document_name.text
                  }} -->
                </h6>
                <small class="text-muted">{{ $t("document_urgency") }}</small>
                <h6>
                  {{
                    state.detail.document_urgency === "" ||
                    state.detail.document_urgency === undefined
                      ? "-"
                      : state.detail.document_urgency.text
                  }}
                </h6>
                <!-- <small class="text-muted">{{ $t("document_speed") }}</small>
                <h6>
                  {{
                    state.detail.document_speed === "" ||
                    state.detail.document_speed === undefined
                      ? "-"
                      : state.detail.document_speed.text
                  }}
                </h6> -->
              </div>

              <div class="col-md-6 pull-left">
                <small class="text-muted">{{ $t("document_summary") }}</small>
                <h6>
                  <span v-if="state.detail.description === ''">-</span>
                  <span v-else v-html="state.detail.description"></span>
                </h6>
                <small class="text-muted">{{ $t("document_number") }}</small>
                <h6>
                  {{
                    state.detail.no_opd === "" ||
                    state.detail.no_opd === undefined
                      ? "-"
                      : state.detail.no_opd
                  }}
                </h6>
                <!-- <small class="text-muted">{{ $t("status") }}</small>
                <h6>
                  {{
                    state.detail.status === "" ||
                    state.detail.status === undefined
                      ? "-"
                      : state.detail.status
                  }}
                </h6> -->
                <small class="text-muted"
                  >{{ $t("page") }}/{{ $t("attachment") }}</small
                >
                <h6>
                  {{
                    state.detail.pages === "" ||
                    state.detail.pages === undefined
                      ? "-"
                      : state.detail.pages
                  }}
                  /
                  {{
                    state.detail.attachment_total === "" ||
                    state.detail.attachment_total === undefined
                      ? "-"
                      : state.detail.attachment_total +
                        " " +
                        state.detail.attachment_type.text
                  }}
                </h6>
                <small class="text-muted">{{ $t("to") }}</small>
                <h6 v-if="state.detail.receiver === undefined">-</h6>
                <h6 v-else-if="!state.detail.receiver.length">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.receiver"
                      :key="rec.id"
                    >
                      <td>{{ index + 1 + ". " + rec.label }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </h6>
                <ModalDefault
                  v-show="isVisible"
                  :title="modalTitle"
                  :content="modalContent"
                  :action="ActionChangeable"
                  :confirmation="isConfirmation"
                  :information="isInformation"
                  @close="onClose"
                />
                <small class="text-muted">{{ $t("cc") }}</small>
                <h6 v-if="state.detail.carbon === undefined">-</h6>
                <h6 v-else-if="!state.detail.carbon.length">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in state.detail.carbon"
                      :key="rec.id"
                    >
                      <td>{{ index + 1 + ". " + rec.label }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>
                </h6>
                <small class="text-muted">Eksternal Penerima</small>
                <h6>{{ state.detail.receiverex }}</h6>
                <small class="text-muted">Eksternal Tembusan</small>
                <h6>{{ state.detail.carbonex }}</h6>
              </div>
              <div class="col-md-12 justify-content-md-center">
                <div class="col-md-12 pull-left">
                  <small class="text-muted">{{ $t("file") }}</small>
                  <br />
                  <h6 v-if="!containFile">-</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t("file") }}</th>
                      <th>{{ $t("sender") }}</th>
                      <th>{{ $t("date") }}</th>
                      <th>Buka File</th>
                    </tr>
                    <tr
                      v-for="file in state.detail.files"
                      v-bind:key="file.filename"
                    >
                      <td>
                        {{ file.filename }}
                      </td>
                      <td>
                        {{
                          state.detail.senderUnit === undefined
                            ? "-"
                            : state.detail.senderUnit
                        }}
                      </td>
                      <td>
                        {{ formatDateTime(file.created_at) }}
                      </td>
                      <td>
                        <!-- <a
                          class="btn btn-success text-white"
                          @click="downloadFileForce(file.url)"
                          title="Download File"
                          ><i class="fa fa-download text-white"></i>
                        </a> -->
                        <!-- v-if="file.filename.includes('.pdf')" -->
                        <a                          
                          @click="prev(file.uuid)"
                          class="btn btn-info text-white"
                          title="View File"
                          ><i class="fa fa-eye text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <!-- <small class="text-muted">Induk</small>
            <h6>{{state.detail.parent_id}}</h6>
            <small class="text-muted p-t-30 db">Deskripsi</small>
            <h6>{{(state.detail.description === "" || state.detail.description === undefined)? '-' : state.detail.description}}</h6>-->
                  <!-- <small class="text-muted p-t-30 db">{{
                    $t("recording_date")
                  }}</small>
                  <h6>
                    {{
                      submitted === "" || submitted === undefined
                        ? "-"
                        : submitted
                    }}
                  </h6>
                  <small class="text-muted p-t-30 db">{{
                    $t("delivery_date")
                  }}</small>
                  <h6>
                    {{
                      modified === "" || modified === undefined ? "-" : modified
                    }}
                  </h6> -->
                  <div
                    v-if="state.detail.decline || state.detail.approval == 4"
                  >
                    <small
                      class="text-muted p-t-30 db"
                      v-if="state.detail.approval == 1"
                      >{{ $t("note") }}</small
                    >
                    <small
                      class="text-muted p-t-30 db"
                      v-else-if="state.detail.approval == 3"
                      >{{ $t("rejected_reason") }}</small
                    >
                    <small
                      class="text-muted p-t-30 db"
                      v-else-if="state.detail.approval == 5"
                      >{{ $t("retracted_reason") }}</small
                    >
                    <small class="text-muted p-t-30 db" v-else>{{
                      $t("revision_note")
                    }}</small>
                    <h6>{{ state.detail.decline }}</h6>
                  </div>
                </div>
              </div>
            </gtTab>
            <gtTab title="Tracking Surat">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <TreeView class="item" :model="treeData" />
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>
          </gtTabs>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div v-if="state.detail.can_approve && canApprove">
            <small class="text-muted p-t-30 db">{{ $t("note") }}</small>
            <wysiwyg v-model="myHTML" />
            <br />
          </div>
          <div
            class="form-actions"
            v-if="state.detail.can_approve && canApprove"
          >
            <button
              class="btn btn-success"
              @click="showModal({ type: 'submit', data: 1 })"
            >
              <i class="fa fa-check"></i> {{ $t("agree") }}
            </button>
            &nbsp;
            <button
              class="btn btn-warning"
              @click="showModal({ type: 'submit', data: 4 })"
            >
              <i class="fa fa-edit"></i> {{ $t("revision") }}
            </button>
            &nbsp;
            <button
              class="btn btn-danger"
              @click="showModal({ type: 'submit', data: 3 })"
            >
              <i class="fa fa-times"></i> {{ $t("decline") }}
            </button>
            &nbsp;
          </div>
          <div class="form-actions" v-else>
            <button
              type="button"
              @click="showModal({ type: 'cancel', data: 0 })"
              class="btn btn-inverse"
              title="Kembali"
            >
              <i class="fa fa-arrow-left"></i> {{ $t("back") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-body printableArea">
      <h4>Keterangan</h4>
      <table>
        <tr>
          <td>
            <button type="button" class="btn btn-info pull-left">
              <i class="fa fa-print"></i> {{ $t("print") }}
            </button>
          </td>
          <td>Print Surat Pengantar</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <i
              class="fa fa-envelope"
              style="
                cursor: pointer;
                background: blue;
                padding: 4px;
                color: #fff;
                border-radius: 5px;
              "
            ></i>
          </td>
          <td>Notifikasi surat belum di konfirmasi</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <i
              class="fa fa-envelope"
              style="
                cursor: pointer;
                background: green;
                padding: 4px;
                color: #fff;
                border-radius: 5px;
              "
            ></i>
          </td>
          <td>Notifikasi surat sudah di konfirmasi</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <i
              class="fa fa-envelope"
              style="
                cursor: pointer;
                background: red;
                padding: 4px;
                color: #fff;
                border-radius: 5px;
              "
            ></i>
          </td>
          <td>Notifikasi surat di retur</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <a class="btn btn-success text-white" title="Download File"
              ><i class="fa fa-download text-white"></i>
            </a>
          </td>
          <td>Untuk mendownload file terlampir</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <a class="btn btn-info text-white" title="View File"
              ><i class="fa fa-eye text-white"></i>
            </a>
          </td>
          <td>Untuk view file terlampir</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
  },
  data() {
    return {
      myHTML: "",
      approver: "",
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isModalVisible: false,
      isConfirmation: false,
      isInformation: false,
      ActionChangeable: null,
    };
  },
  computed: {
    canApprove() {
      if (this.$store.state.profile.permissions.document_out_confirm) {
        return this.$store.state.profile.permissions.document_out_confirm
          .create;
      }
      return false;
    },
    containFile() {
      let data = false;
      if (
        this.state.detail.files != undefined &&
        this.state.detail.files.length
      ) {
        data = true;
      }
      return data;
    },
    treeData() {
      return this.$store.state.suratKeluar.detail.tracking !== undefined
        ? this.$store.state.suratKeluar.detail.tracking
        : {};
    },
    state() {
      return this.$store.state.suratKeluar;
    },
    submitted() {
      return this.formatDateTime(this.state.detail.created_at);
    },
    modified() {
      return this.formatDateTime(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    showModal(option) {
      this.isVisible = true;
      if (option.approval == 2) {
        this.modalTitle = this.$t("not_confirmed_yet");
        this.modalContent = "<p>" + this.$t("not_confirmed_yet") + "";
        this.isConfirmation = false;
      } else if (option.approval == 1) {
        this.modalTitle = this.$t("confirmed");
        this.modalContent = "<p>" + this.$t("confirmed") + "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("date") +
          " : " +
          (option.updated_at == null ? "-" : option.updated_at) +
          "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("by") +
          " : " +
          (option.user == null ? "-" : option.user) +
          "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("message") +
          " : " +
          (option.message == null ? "-" : option.message) +
          "</p>";
        this.isConfirmation = false;
      } else if (option.approval == 3) {
        this.modalTitle = this.$t("returned");
        this.modalContent = "<p>" + this.$t("returned") + "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("date") +
          " : " +
          (option.updated_at == null ? "-" : option.updated_at) +
          "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("by") +
          " : " +
          (option.user == null ? "-" : option.user) +
          "</p>";
        this.modalContent +=
          "<p>" +
          this.$t("message") +
          " : " +
          (option.message == null ? "-" : option.message) +
          "</p>";
        this.isConfirmation = false;
      } else if (option.type == "cancel") {
        this.modalTitle = this.$t("back_q");
        this.modalContent = "<p>" + this.$t("back_confirm") + "</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      } else if (option.type == "submit") {
        this.modalTitle = this.$t("send_data_q");
        this.modalContent = "<p>" + this.$t("document_confirm_q") + "</p>";
        this.isConfirmation = true;
        if (option.data == 1) {
          this.ActionChangeable = this.approve;
        } else if (option.data == 3) {
          this.ActionChangeable = this.decline;
        } else if (option.data == 4) {
          this.ActionChangeable = this.revision;
        }
      }
    },
    onClose() {
      this.isVisible = false;
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("suratKeluar/STATE", state);
      this.$store.dispatch(
        "suratKeluar/getSuratKeluarById",
        this.$route.params
      );
    },
    goBack() {
      this.$router.push({
        name: "suratKeluar",
      });
    },
    approve() {
      let payload = {
        document: this.$route.params.id,
        approval: 1,
        approver: this.state.detail.approver,
        message: this.myHTML,
      };
      this.$store.dispatch("suratKeluar/approveDoc", JSON.stringify(payload));
    },
    revision() {
      let payload = {
        document: this.$route.params.id,
        approval: 4,
        approver: this.state.detail.approver,
        message: this.myHTML,
      };
      this.$store.dispatch("suratKeluar/approveDoc", JSON.stringify(payload));
    },
    decline() {
      let payload = {
        document: this.$route.params.id,
        approval: 3,
        approver: this.state.detail.approver,
        message: this.myHTML,
      };
      this.$store.dispatch("suratKeluar/approveDoc", JSON.stringify(payload));
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_out",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png"); //or any other extension
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    download(filepath, filename) {
      axios
        .post("document_out/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          // downloadLink.click();
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
        });
    },
    print() {
      let carbon = [];
      if (this.state.detail.carbon.length) {
        carbon = [
          {
            text: [
              {
                width: "auto",
                text: "Tembusan",
              },
              {
                width: "auto",
                text: " :",
              },
            ],
          },
          {
            ul: this.state.detail.carbon.map((value) => {
              return value.text;
            }),
            margin: [30, 0, 0, 10],
          },
        ];
      }
      let receiver = [];
      if (this.state.detail.receiver.length) {
        receiver = [
          {
            text: [
              {
                width: "auto",
                text: "Kepada",
              },
              {
                width: "auto",
                text: " :",
              },
            ],
          },
          {
            ul: this.state.detail.receiver.map((value) => {
              return value.text;
            }),
            margin: [30, 0, 0, 10],
          },
        ];
      }
      var documentDefinition = {
        content: [
          {
            text: [
              {
                text: "PEMERINTAH DAERAH\n",
                // alignment:'left',
                fontSize: 16,
                bold: true,
              },
              {
                text: "DAERAH ISTIMEWA YOGYAKARTA\n",
                // alignment:'left',
                fontSize: 23,
                bold: true,
              },
              {
                text: "",
                fontSize: 9,
              },
            ],
            alignment: "center",
            marginBottom: 3,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 2,
              },
            ],
            marginTop: 3,
            marginBottom: 10,
          },
          {
            columns: [
              {
                text: [
                  {
                    width: "auto",
                    text: "Nomor Surat",
                  },
                  {
                    width: "auto",
                    text: " : ",
                  },
                  {
                    width: "auto",
                    text: this.state.detail.document_number,
                  },
                ],
              },
              {
                qr: this.state.detail.document_number,
                fit: 80,
                alignment: "right",
              },
            ],
            marginTop: 5,
          },
          receiver,
          {
            text: [
              {
                width: "auto",
                text: "Dari",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.senderUnit,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Halaman / Lampiran",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.pages + " / ",
              },
              {
                width: "auto",
                text:
                  this.state.detail.attachment_total +
                  " ( " +
                  this.state.detail.attachment_type.text +
                  " )",
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Perihal",
              },
              {
                width: "auto",
                text: " : ",
              },
              {
                width: "auto",
                text: this.state.detail.subject,
              },
            ],
            marginBottom: 10,
          },
          {
            text: [
              {
                width: "auto",
                text: "Ringkasan",
              },
              {
                width: "auto",
                text: " : ",
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                x2: 515,
                y1: 0,
                y2: 0,
                lineWidth: 2,
              },
            ],
            marginTop: 3,
            marginBottom: 3,
          },
          {
            table: {
              marginTop: 5,
              headerRows: 0,
              widths: ["*"],
              body: [
                [
                  {
                    columns: [
                      {
                        text: this.state.detail.description,
                        alignment: "center",
                        margin: [0, 10],
                      },
                    ],
                  },
                ],
              ],
            },
            marginBottom: 40,
          },
          carbon,
        ],
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4",
      };
      pdfMake.createPdf(documentDefinition).print();
    },
  },
};
</script>
